//Install
import React, { useState, useEffect } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Header from "../../Header";
//Install
import Footer from "../../Footer";

//Element
function Overview(){
    //Element
    document.title = "Kurumsal Bilgiler - Renga.com.tr"
}
//Element
function Upgrade(e){
    //Element
    e.preventDefault()
    //Element
    let Address, Telephone, Fax, Email, Showroom, Showroom_Telephone, Showroom_Email
    //Element
    Address             = document.getElementById( "Web_C51G2W" ).value
    //Element
    Telephone           = document.getElementById( "Web_E499FN" ).value
    //Element
    Fax                 = document.getElementById( "Web_MI2441" ).value
    //Element
    Email               = document.getElementById( "Web_U8L57P" ).value
    //Element
    Showroom            = document.getElementById( "Web_P67G7H" ).value
    //Element
    Showroom_Telephone  = document.getElementById( "Web_K640L4" ).value
    //Element
    Showroom_Email      = document.getElementById( "Web_Q48R4Y" ).value
    //Element
    const Fetch = async() => {
        //Element
        const Public = await Axios.get( "https://api.renga.com.tr/settings/update", {
            //Element
            params : {
                //Element
                Address            : Address,
                //Element
                Telephone          : Telephone,
                //Element
                Fax                : Fax,
                //Element
                Email              : Email ,
                //Element
                Showroom           : Showroom,
                //Element
                Showroom_Telephone : Showroom_Telephone,
                //Element
                Showroom_Email     : Showroom_Email
            }
        })
        //Element
        .catch( error => {
            //Element
            console.log( error )
        })
    }
    //Element
    Fetch()
    //Element
    window.location.href = "/setting"
}
//Element
function Default(){
    //Element
    Overview()
    //Element
    let ID, Address, Telephone, Fax, Email, Showroom, Showroom_Telephone, Showroom_Email
    //Element
    const [ SW537H, X52265 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Fetch = async() => {
            //Element
            const Public = await Axios.get( "https://api.renga.com.tr/settings", {} )
            //Element
            X52265( Public.data )
        }
        //Element
        Fetch()
    }, [] )
    //Element
    SW537H.map( (Data) => {
        //Element
        ID                 = Data.ID
        //Element
        Address            = Data.Address
        //Element
        Telephone          = Data.Telephone
        //Element
        Fax                = Data.Fax
        //Element
        Email              = Data.Email
        //Element
        Showroom           = Data.Showroom
        //Element
        Showroom_Telephone = Data.Showroom_Telephone
        //Element
        Showroom_Email     = Data.Showroom_Email
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v9339k" },
        //Element
        React.createElement( Header, { ID: "0", Title: "Merhaba, Renga.com.tr", Status: "Kurumsal Bilgiler" } ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ga8x38" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                                    //Element
                                    "Kurumsal Bilgiler"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                                    //Element
                                    "Renga.com.tr"
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
                                //Element
                                React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: Upgrade },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "ID"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_M1M849", name: "Web_M1M849", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Merkez Ofis Adres"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_C51G2W", name: "Web_C51G2W", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Address, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Telefon"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_E499FN", name: "Web_E499FN", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Telephone, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "E-mail"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_U8L57P", name: "Web_U8L57P", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Email, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Fax"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_MI2441", name: "Web_MI2441", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Fax, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "İstoç Renga Showroom Adres"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_P67G7H", name: "Web_P67G7H", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Showroom, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Telefon"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_K640L4", name: "Web_K640L4", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Showroom_Telephone, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "E-mail"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_Q48R4Y", name: "Web_Q48R4Y", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Showroom_Email, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "button", { id: "Web_MM727B", name: "Web_MM727B", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                                                    //Element
                                                    "Gönder"
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( Footer, {} )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default;