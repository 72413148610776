//Install
import React, { Component } from "react";
//Install
import Axios from "axios";
//Install
import { ReactComponent as Icon } from "../../../Media/Icon/012.svg";
//Install
import { ReactComponent as Edit } from "../../../Media/Icon/013.svg";

//Element
class Upload extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            SelectedFile : null,
            //Element
            FileName     : null
        }
    }
    //Element
    FileChange = (e) => {
        //Element
        const Data = new FormData()
        //Element
        let SVG     = document.getElementsByClassName( "p43687" )[0]
        //Element
        let Icon    = document.querySelector( ".f5f48j" )
        //Element
        let Image   = document.getElementsByClassName( "klf458" )[0]
        //Element
        let Preview = document.getElementById( "PrizePreviewImage" )
        //Element
        SVG.classList.add( "d-none" )
        //Element
        Image.classList.remove( "d-none" )
        //Element
        if( e.target.files[0] != null ){
            //Element
            this.setState({
                //Element
                SelectedFile : e.target.files[0],
                //Element
                FileName     : e.target.files[0].name
            })
            //Element
            Preview.src = URL.createObjectURL( e.target.files[0] )
            //Element
            Data.append( "image", e.target.files[0] )
            //Element
            Axios.post( "https://api.renga.com.tr/upload", Data )
            //Element
            .then( Response => {
                //Element
                sessionStorage.removeItem( "PrizeFeaturedImage" )
                //Element
                sessionStorage.setItem( "PrizeFeaturedImage",  Response.data )
            })
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 mb-3 c971b1" },
                //Element
                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                    //Element
                    "Fotoğraf"
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                    //Element
                    "Sadece *.png, *.jpg ve *.jpeg Formatları Kabul Edilir."
                )
            ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 ix0q18" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 d8z4l3" },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 kl579w" },
                        //Element
                        React.createElement( "div", { className: "float-start w-auto p-0 m-0 p43687" },
                            //Element
                            React.createElement( Icon, {} )
                        ),
                        //Element
                        React.createElement( "div", { className: "float-start w-auto p-0 m-0 d-none klf458" },
                            //Element
                            React.createElement( "img", { id: "PrizePreviewImage", alt: "Preview Image", title: "Preview Image", src: "", width: "200", height: "auto" } )
                        ),
                        //Element
                        React.createElement( "label", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute rounded-circle f5f48j", htmlFor: "Web_N5X6E9" },
                            //Element
                            React.createElement( Edit, {} ),
                            //Element
                            React.createElement( "input", { id: "Web_N5X6E9", name: "Web_N5X6E9", className: "d-none", type: "file", accept: ".png, .jpg, .jpeg", onChange: this.FileChange } )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Upload;