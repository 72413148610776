//Install
import React, { useState, useEffect } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import { CKEditor } from "@ckeditor/ckeditor5-react";
//Install
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//Install
import Upload from "../../Shortcode/Product/Image";

//Element
function Add(e){
    //Element
    e.preventDefault()
    //Element
    const Data = new FormData()
    //Element
    let ID
    //Element
    let Title, SubTitle, Summary, Image, Care, Features, Faq, Variety, Bottle, Volume, Date, Author
    //Element
    let PrimaryColor, SecondColor, ThirdColor
    //Element
    let PrimaryImage, SecondImage, ThirdImage
    //Element
    Title        = document.getElementById( "Web_U45Q45" ).value
    //Element
    SubTitle     = document.getElementById( "Web_LK55R0" ).value
    //Element
    Summary      = document.getElementsByClassName( "ck-editor__editable_inline" )[0].innerHTML
    //Element
    Image        = sessionStorage.getItem( "ProductFeaturedImage" )
    //Element
    Care         = document.getElementById( "Web_H912Y9" ).value
    //Element
    Features     = document.getElementById( "Web_R8401X" ).value
    //Element
    Faq          = document.getElementById( "Web_Y050C7" ).value
    //Element
    Variety      = document.getElementById( "Web_GV376I" ).value
    //Element
    Bottle       = document.getElementById( "Web_D642MB" ).value
    //Element
    Volume       = document.getElementById( "Web_T134PW" ).value
    //Element
    Date         = document.getElementById( "Web_P65395" ).value
    //Element
    Author       = document.getElementById( "Web_Y71949" ).value
    //Element
    PrimaryColor = document.getElementById( "Web_GO973H" ).value
    //Element
    SecondColor  = document.getElementById( "Web_EW280E" ).value
    //Element
    ThirdColor   = document.getElementById( "Web_R4399Q" ).value
    //Element
    PrimaryImage = sessionStorage.getItem( "ProductPrimaryImage" )
    //Element
    SecondImage  = sessionStorage.getItem( "ProductSecondImage" )
    //Element
    ThirdImage   = sessionStorage.getItem( "ProductThirdImage" )
    //Element
    const Fetch = async() => {
        //Element
        const Public = await Axios.get( "https://api.renga.com.tr/product/add", {
            //Element
            params : {
                //Element
                Title    : Title,
                //Element
                SubTitle : SubTitle,
                //Element
                Summary  : Summary,
                //Element
                Image    : Image,
                //Element
                Care     : Care,
                //Element
                Features : Features,
                //Element
                Faq      : Faq,
                //Element
                Variety  : Variety,
                //Element
                Bottle   : Bottle,
                //Element
                Volume   : Volume,
                //Element
                Date     : Date,
                //Element
                Author   : Author
            }
        })
        //Element
        ID = Public.data.insertId
        //Element
        const Color = await Axios.get( "https://api.renga.com.tr/product/color/add", {
            //Element
            params : {
                //Element
                ID      : ID,
                //Element
                First   : PrimaryColor,
                //Element
                Second  : SecondColor,
                //Element
                Third   : ThirdColor
            }
        })
        //Element
        const Photo = await Axios.get( "https://api.renga.com.tr/product/image/add", {
            //Element
            params : {
                //Element
                ID      : ID,
                //Element
                First   : PrimaryImage,
                //Element
                Second  : SecondImage,
                //Element
                Third   : ThirdImage
            }
        })
    }
    //Element
    Fetch()
    //Element
    sessionStorage.removeItem( "ProductPrimaryImage" )
    //Element
    sessionStorage.removeItem( "ProductSecondImage" )
    //Element
    sessionStorage.removeItem( "ProductThirdImage" )
    //Element
    sessionStorage.removeItem( "ProductFeaturedImage" )
    //Element
    window.location.href = "/product-order"
}
//Element
function New(){
    //Element
    let Now   = new Date()
    //Element
    let Today = Now.toLocaleDateString( "tr-TR" )
    //Element
    const [ HR384Q, H01AR4 ] = useState([])
    //Element
    const [ U13KG9, X595YJ ] = useState([])
    //Element
    const [ QU1204, MX9D03 ] = useState([])
    //Element
    const [ I46G4X, P6631T ] = useState([])
    //Element
    useEffect( () => {
        //Element
        const Volume = async() => {
            //Element
            const Public = await Axios.get( "https://api.renga.com.tr/volume", {} )
            //Element
            H01AR4( Public.data )
        }
        //Element
        Volume()
        //Element
        const Color = async() => {
            //Element
            const Public = await Axios.get( "https://api.renga.com.tr/color", {} )
            //Element
            X595YJ( Public.data )
        }
        //Element
        Color()
        //Element
        const Bottle = async() => {
            //Element
            const Public = await Axios.get( "https://api.renga.com.tr/bottle", {} )
            //Element
            MX9D03( Public.data )
        }
        //Element
        Bottle()
        //Element
        const Variety = async() => {
            //Element
            const Public = await Axios.get( "https://api.renga.com.tr/variety", {} )
            //Element
            P6631T( Public.data )
        }
        //Element
        Variety()
    }, [])
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Ürün"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Renga.com.tr"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", onSubmit: Add },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_V180MF", name: "Web_V180MF", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Tarih"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_P65395", name: "Web_P65395", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", defaultValue: Today, required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Admin"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_Y71949", name: "Web_Y71949", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", defaultValue: "Renga.com.tr", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ürün Başlığı"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_U45Q45", name: "Web_U45Q45", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Alt Başlığı"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_LK55R0", name: "Web_LK55R0", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Kategori"
                            ),
                            //Element
                            React.createElement( "select", { id: "Web_GV376I", name: "Web_GV376I", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                //Element
                                I46G4X.map( (Data) => {
                                    //Element
                                    return React.createElement( "option", { key: Data.ID, value: Data.ID },
                                        //Element
                                        Data.Title
                                    )
                                })
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Açıklama"
                            ),
                            //Element
                            React.createElement( CKEditor, { editor: ClassicEditor } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Bakım"
                            ),
                            //Element
                            React.createElement( "textarea", { id: "Web_H912Y9", name: "Web_H912Y9", className: "float-start w-100 p-3 m-0 mt-2 a49170", rows: "5", cols: "20", maxlenght: "500", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Özellikler"
                            ),
                            //Element
                            React.createElement( "textarea", { id: "Web_R8401X", name: "Web_R8401X", className: "float-start w-100 p-3 m-0 mt-2 a49170", rows: "5", cols: "20", maxlenght: "500", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "S.S.S"
                            ),
                            //Element
                            React.createElement( "textarea", { id: "Web_Y050C7", name: "Web_Y050C7", className: "float-start w-100 p-3 m-0 mt-2 a49170", rows: "5", cols: "20", maxlenght: "500", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Renk"
                            ),
                            //Element
                            React.createElement( "select", { id: "Web_GO973H", name: "Web_GO973H", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                //Element
                                U13KG9.map( (Data) => {
                                    //Element
                                    return React.createElement( "option", { key: Data.ID, value: Data.ID },
                                        //Element
                                        Data.Title
                                    )
                                })
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Renk"
                            ),
                            //Element
                            React.createElement( "select", { id: "Web_EW280E", name: "Web_EW280E", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                //Element
                                U13KG9.map( (Data) => {
                                    //Element
                                    return React.createElement( "option", { key: Data.ID, value: Data.ID },
                                        //Element
                                        Data.Title
                                    )
                                })
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Renk"
                            ),
                            //Element
                            React.createElement( "select", { id: "Web_R4399Q", name: "Web_R4399Q", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                //Element
                                U13KG9.map( (Data) => {
                                    //Element
                                    return React.createElement( "option", { key: Data.ID, value: Data.ID },
                                        //Element
                                        Data.Title
                                    )
                                })
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Hacim"
                            ),
                            //Element
                            React.createElement( "select", { id: "Web_T134PW", name: "Web_T134PW", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                //Element
                                HR384Q.map( (Data) => {
                                    //Element
                                    return React.createElement( "option", { key: Data.ID, value: Data.ID },
                                        //Element
                                        Data.Title
                                    )
                                })
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Matara"
                            ),
                            //Element
                            React.createElement( "select", { id: "Web_D642MB", name: "Web_D642MB", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                //Element
                                QU1204.map( (Data) => {
                                    //Element
                                    return React.createElement( "option", { key: Data.ID, value: Data.ID },
                                        //Element
                                        Data.Title
                                    )
                                })
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_R494VD" },
                                        //Element
                                        "Fotoğraf"
                                    ),
                                    //Element
                                    React.createElement( Upload, { ID: "Web_R494VD", For: "Web_N87TJ3", SVG: "Web_V32KA9", Image: "Web_V5K852" } )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_Y123I3" },
                                        //Element
                                        "Fotoğraf"
                                    ),
                                    //Element
                                    React.createElement( Upload, { ID: "Web_Y123I3", For: "Web_Y8BN79", SVG: "Web_QR150K", Image: "Web_XG45T3" } )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:4 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-100 p-0 m-0 mb-2 kq045f", htmlFor: "Web_W5F6C8" },
                                        //Element
                                        "Fotoğraf"
                                    ),
                                    //Element
                                    React.createElement( Upload, { ID: "Web_W5F6C8", For: "Web_E2F4K5", SVG: "Web_Q0V66Y", Image: "Web_Y1V817" } )
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                        //Element
                        React.createElement( "button", { id: "Web_D845DF", name: "Web_D845DF", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                            //Element
                            "Gönder"
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default New;