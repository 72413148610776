//Install
import React from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Header from "../../Header";
//Install
import Footer from "../../Footer";
//Install
import Upload from "./Upload";
//Install
import Edit from "./Edit";

//Element
function Overview(){
    //Element
    document.title = "Ürün - Renga.com.tr"
}
//Element
function Update(){
    //Element
    Overview()
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v9339k" },
        //Element
        React.createElement( Header, { ID: "0", Title: "Merhaba, Renga.com.tr", Status: "Ürün" } ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ga8x38" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( Upload, {} )
                    ),
                    //Element
                    React.createElement( Col, { md:8 },
                        //Element
                        React.createElement( Edit, {} )
                    )
                )
            )
        ),
        //Element
        React.createElement( Footer, {} )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Update;