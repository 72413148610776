//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function Upgrade(e){ 
    //Element
    e.preventDefault()
    //Element
    let ID, Title, One, Two, Three, Image
    //Element
    ID      = document.getElementById( "Web_HZ04O4" ).value
    //Element
    Title   = document.getElementById( "Web_WY1M47" ).value
    //Element
    One     = document.getElementById( "Web_P9340F" ).value
    //Element
    Two     = document.getElementById( "Web_BZ457J" ).value
    //Element
    Three   = document.getElementById( "Web_P49O4A" ).value
    //Element
    Image   = sessionStorage.getItem( "PrizeFeaturedImage" )
    //Element
    const Fetch = async() => {
        //Element
        const Public = await Axios.get( "https://api.renga.com.tr/prize/update", {
            //Element
            params : {
                //Element
                ID      : ID,
                //Element
                Title   : Title,
                //Element
                One     : One,
                //Element
                Two     : Two,
                //Element
                Three   : Three,
                //Element
                Image   : Image
            }
        })
        //Element
        .catch( error => {
            //Element
            console.log( error )
        })
    }
    //Element
    Fetch()
    //Element
    window.location.href = "/prize-order"
}
//Element
function Delete(e){ 
    //Element
    e.preventDefault()
    //Element
    let ID = document.getElementById( "Web_HZ04O4" ).value
    //Element
    const Fetch = async() => {
        //Element
        const Public = await Axios.get( "https://api.renga.com.tr/prize/delete", {
            //Element
            params : {
                //Element
                ID : ID
            }
        })
        //Element
        .catch( error => {
            //Element
            console.log( error )
        })
    }
    //Element
    Fetch()
    //Element
    window.location.href = "/prize-order"
}
//Element
function Edit(){
    //Element
    let Now      = new Date()
    //Element
    let Today    = Now.toLocaleDateString( "tr-TR" )
    //Element
    let Source   = "https://api.renga.com.tr/images/"
    //Element
    const { ID } = useParams()
    //Element
    let Title, One, Two, Three, Image
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Fetch = async() => {
            //Element
            const Public = await Axios.get( "https://api.renga.com.tr/prize/select", {
                //Element
                params : {
                    //Element
                    ID      : ID,
                    //Element
                    Title   : Title,
                    //Element
                    One     : One,
                    //Element
                    Two     : Two,
                    //Element
                    Three   : Three,
                    //Element
                    Image   : Image
                }
            } )
            //Element
            C68006( Public.data )
        }
        //Element
        Fetch()
    }, [] )
    //Element
    G7TI26.map( (Data) => {
        //Element
        Title   = Data.Title
        //Element
        One     = Data.One
        //Element
        Two     = Data.Two
        //Element
        Three   = Data.Three
        //Element
        Image   = Data.Image
    })
    //Element
    useEffect( () => {
        //Element
        let URL      = Source + Image
        //Element
        const Photo = document.querySelector( "#PrizePreviewImage" )
        //Element
        Photo.src   = URL
        //Element
        document.getElementsByClassName( "klf458" )[0].classList.remove( "d-none" )
        //Element
        document.getElementsByClassName( "p43687" )[0].classList.add( "d-none" )
    })
    //Element
    sessionStorage.getItem( "PrizeFeaturedImage", Image )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Ödül"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Renga.com.tr"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_HZ04O4", name: "Web_HZ04O4", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ödül Başlığı"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_WY1M47", name: "Web_WY1M47", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Title, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "#1"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_P9340F", name: "Web_P9340F", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: One, type: "text" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "#2"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_BZ457J", name: "Web_BZ457J", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Two, type: "text" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "#3"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_P49O4A", name: "Web_P49O4A", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Three, type: "text" } )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: Delete },
                                        //Element
                                        "Sil"
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:6 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                    //Element
                                    React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: Upgrade },
                                        //Element
                                        "Güncelle"
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;