//Install
import React, { useState, useEffect } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import { CKEditor } from "@ckeditor/ckeditor5-react";
//Install
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//Install
import Header from "../../Header";
//Install
import Footer from "../../Footer";

//Element
function Overview(){
    //Element
    document.title = "Sürdürülebilirlik - Renga.com.tr"
}
//Element
function Upgrade(e){
    //Element
    e.preventDefault()
    //Element
    let Title, Summary
    //Element
    Title   = document.getElementById( "Web_I3694B" ).value
    //Element
    Summary = document.getElementsByClassName( "ck-editor__editable_inline" )[0].innerHTML
    //Element
    const Fetch = async() => {
        //Element
        const Public = await Axios.get( "https://api.renga.com.tr/sustainability/update", {
            //Element
            params : {
                //Element
                Title   : Title,
                //Element
                Summary : Summary
            }
        })
        //Element
        .catch( error => {
            //Element
            console.log( error )
        })
    }
    //Element
    Fetch()
    //Element
    window.location.href = "/sustainability"
}
//Element
function Default(){
    //Element
    Overview()
    //Element
    let Now   = new Date()
    //Element
    let Today = Now.toLocaleDateString( "tr-TR" )
    //Element
    let ID, Title, Summary
    //Element
    const [ XN336I, F757J5 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Fetch = async() => {
            //Element
            const Public = await Axios.get( "https://api.renga.com.tr/sustainability", {} )
            //Element
            F757J5( Public.data )
        }
        //Element
        Fetch()
    }, [] )
    //Element
    XN336I.map( (Data) => {
        //Element
        ID      = Data.ID
        //Element
        Title   = Data.Title
        //Element
        Summary = Data.Summary
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v9339k" },
        //Element
        React.createElement( Header, { ID: "0", Title: "Merhaba, Renga.com.tr", Status: "Sürdürülebilirlik" } ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ga8x38" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                                    //Element
                                    "Sürdürülebilirlik"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                                    //Element
                                    "Renga.com.tr"
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
                                //Element
                                React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: Upgrade },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "ID"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_R705TJ", name: "Web_R705TJ", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Ad"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_I3694B", name: "Web_I3694B", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Title, type: "text", required: "required" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Açıklama"
                                        ),
                                        //Element
                                        React.createElement( CKEditor, { editor: ClassicEditor, data: Summary } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                        //Element
                                        React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                            //Element
                                            "Tarih"
                                        ),
                                        //Element
                                        React.createElement( "input", { id: "Web_J307PS", name: "Web_J307PS", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", placeholder: Today, type: "text", required: "required", disabled: "disabled" } )
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "button", { id: "Web_OH5435", name: "Web_OH5435", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                                            //Element
                                            "Gönder"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( Footer, {} )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default;