//Install
import React from "react";
//Install
import Axios from "axios";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { CKEditor } from "@ckeditor/ckeditor5-react";
//Install
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//Element
function Add(e){
    //Element
    e.preventDefault()
    //Element
    let Title, Summary, Image, Date, Category, Author
    //Element
    Title    = document.getElementById( "Web_W26U32" ).value
    //Element
    Summary  = document.getElementsByClassName( "ck-editor__editable_inline" )[0].innerHTML
    //Element
    Image    = sessionStorage.getItem( "BlogFeaturedImage" )
    //Element
    Date     = document.getElementById( "Web_P0962M" ).value
    //Element
    Category = document.getElementById( "Web_N66390" ).value
    //Element
    Author   = document.getElementById( "Web_M8EL55" ).value
    //Element
    const Fetch = async() => {
        //Element
        const Public = await Axios.get( "https://api.renga.com.tr/blog/add", {
            //Element
            params : {
                //Element
                Title    : Title,
                //Element
                Summary  : Summary,
                //Element
                Image    : Image,
                //Element
                Date     : Date,
                //Element
                Category : Category,
                //Element
                Author   : Author
                
            }
        })
        //Element
        .catch( error => {
            //Element
            console.log( error )
        })
    }
    //Element
    Fetch()
    //Element
    window.location.href = "/blog-order"
}
//Element
function New(){
    //Element
    let Now   = new Date()
    //Element
    let Today = Now.toLocaleDateString( "tr-TR" )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Blog"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Renga.com.tr"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: Add },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_R6X85C", name: "Web_R6X85C", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Tarih"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_P0962M", name: "Web_P0962M", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", defaultValue: Today, required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Yazar"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_M8EL55", name: "Web_M8EL55", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", defaultValue: "Renga.com.tr", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Blog Başlığı"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_W26U32", name: "Web_W26U32", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Bilgilendirme"
                            ),
                            //Element
                            React.createElement( CKEditor, { editor: ClassicEditor } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Kategori"
                            ),
                            //Element
                            React.createElement( "select", { id: "Web_N66390", name: "Web_N66390", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                //Element
                                React.createElement( "option", { value: "Blog" },
                                    //Element
                                    "Blog"
                                )
                            )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                        //Element
                        React.createElement( "button", { id: "Web_M94E1F", name: "Web_M94E1F", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                            //Element
                            "Gönder"
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default New;