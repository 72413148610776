//Install
import React, { useEffect } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Card from "./Card";
//Install
import Blog from "./Blog";
//Install
import Header from "../Header";
//Install
import Footer from "../Footer";
//Install
import Product from "./Product";
//Install
import Factory from "./Factory";
//Install
import Document from "./Document";

//Element
function Overview(){
    //Element
    document.title = "Overview - Renga.com.tr"
}
//Element
function Default(){
    //Element
    Overview()
    //Element
    const [ R800OV, N574PR ] = React.useState([])
    const [ O2L84Y, G428G5 ] = React.useState([])
    const [ F575WA, N29QZ1 ] = React.useState([])
    const [ U4Z86I, Y0I18B ] = React.useState([])
    const [ XZ5L46, K73IS4 ] = React.useState([])
    const [ M38D81, D2008W ] = React.useState([])
    //Element
    const [ N486E5, PU0D97 ] = React.useState([])
    const [ DHV502, KHP407 ] = React.useState([])
    const [ J5W056, QP306Y ] = React.useState([])
    const [ G19801, S50FO1 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Fetch = async() => {
            //Element
            const Product = await Axios.get( "https://api.renga.com.tr/product/count" )
            //Element
            .then( Response => {
                //Element
                N574PR( Response.data )
            })
            //Element
            const Blog = await Axios.get( "https://api.renga.com.tr/blog/count" )
            //Element
            .then( Response => {
                //Element
                G428G5( Response.data )
            })
            //Element
            const Document = await Axios.get( "https://api.renga.com.tr/document/count" )
            //Element
            .then( Response => {
                //Element
                N29QZ1( Response.data )
            })
            //Element
            const Prize = await Axios.get( "https://api.renga.com.tr/prize/count" )
            //Element
            .then( Response => {
                //Element
                Y0I18B( Response.data )
            })
            //Element
            const Factory = await Axios.get( "https://api.renga.com.tr/factory/count" )
            //Element
            .then( Response => {
                //Element
                K73IS4( Response.data )
            })
            //Element
            const Reference = await Axios.get( "https://api.renga.com.tr/reference/count" )
            //Element
            .then( Response => {
                //Element
                D2008W( Response.data )
            })
            //Element
            const Products = await Axios.get( "https://api.renga.com.tr/product/order" )
            //Element
            .then( Response => {
                //Element
                PU0D97( Response.data )
            })
            //Element
            const Blogs = await Axios.get( "https://api.renga.com.tr/blog/order" )
            //Element
            .then( Response => {
                //Element
                KHP407( Response.data )
            })
            //Element
            const Factories = await Axios.get( "https://api.renga.com.tr/factory/order" )
            //Element
            .then( Response => {
                //Element
                QP306Y( Response.data )
            })
            //Element
            const Documents = await Axios.get( "https://api.renga.com.tr/document/order" )
            //Element
            .then( Response => {
                //Element
                S50FO1( Response.data )
            })
        }
        //Element
        Fetch()
    }, [] )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v9339k" },
        //Element
        React.createElement( Header, { ID: "0", Title: "Merhaba, Renga.com.tr", Status: "Overview" } ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 q0r45m" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        R800OV.map( (Data) => {
                            //Element
                            return React.createElement( Card, { key: Data.Count, ID: Data.Count.toString(), Title: "Ürün", Target: "/" } )
                        })
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        O2L84Y.map( (Data) => {
                            //Element
                            return React.createElement( Card, { key: Data.Count, ID: Data.Count.toString(), Title: "Blog", Target: "/" } )
                        })
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        F575WA.map( (Data) => {
                            //Element
                            return React.createElement( Card, { key: Data.Count, ID: Data.Count.toString(), Title: "Blog", Target: "/" } )
                        })
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        U4Z86I.map( (Data) => {
                            //Element
                            return React.createElement( Card, { key: Data.Count, ID: Data.Count.toString(), Title: "Ödül", Target: "/" } )
                        })
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        XZ5L46.map( (Data) => {
                            //Element
                            return React.createElement( Card, { key: Data.Count, ID: Data.Count.toString(), Title: "Fotoğraf", Target: "/" } )
                        })
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        M38D81.map( (Data) => {
                            //Element
                            return React.createElement( Card, { key: Data.Count, ID: Data.Count.toString(), Title: "Referans", Target: "/" } )
                        })
                    )
                )
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 e279bh" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:8 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-5 pb-4 m-0 mt-4 w60023" },
                            //Element
                            React.createElement( "h3", { className: "float-start w-100 p-0 pb-3 m-0 k3gq53" },
                                //Element
                                "Ürünler"
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 z336ry" },
                                //Element
                                N486E5.map( (Data) => {
                                    //Element
                                    return React.createElement( Product, { key: Data.ID, ID: Data.ID.toString(), Title: Data.Title, Time: Data.Date, Image: Data.Image, Target: Data.ID.toString() } )
                                })
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-5 pb-4 m-0 mt-4 w60023" },
                            //Element
                            React.createElement( "h3", { className: "float-start w-100 p-0 pb-3 m-0 k3gq53" },
                                //Element
                                "Blog"
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 z336ry" },
                                //Element
                                DHV502.map( (Data) => {
                                    //Element
                                    return React.createElement( Blog, { key: Data.ID, ID: Data.ID.toString(), Title: Data.Title, Time: Data.Date, Image: Data.Image, Target: Data.ID.toString() } )
                                })
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-5 pb-4 m-0 mt-4 b129uj" },
                            //Element
                            React.createElement( "h3", { className: "float-start w-100 p-0 pb-3 m-0 el8h94" },
                                //Element
                                "Fabrikamız"
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 w123l4" },
                                //Element
                                J5W056.map( (Data) => {
                                    //Element
                                    return React.createElement( Factory, { key: Data.ID, ID: Data.ID.toString(), Title: Data.Title, Image: Data.Image, Target: "/" } )
                                })
                            )
                        ),
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-5 m-0 mt-4 b129uj" },
                            //Element
                            React.createElement( "h3", { className: "float-start w-100 p-0 pb-3 m-0 el8h94" },
                                //Element
                                "Belgelerimiz"
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 w123l4" },
                                //Element
                                G19801.map( (Data) => {
                                    //Element
                                    return React.createElement( Document, { key: Data.ID, ID: Data.ID.toString(), Title: Data.Title, Image: Data.Image, Target: "/" } )
                                })
                            )
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( Footer, {} )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default;