//Install
import React, { Component } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import { CKEditor } from "@ckeditor/ckeditor5-react";
//Install
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//Install
import Upload from "../../Shortcode/Product/Image";

//Element
class Edit extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        const Location = window.location.origin + "/product-edit/"
        //Element
        const Result   = window.location.href.replace( Location, "" )
        //Element
        this.state = {
            //Element
            ID      : Result,
            //Element
            Summary : "",
            //Element
            Variety : [],
            //Element
            Color   : [],
            //Element
            Volume  : [],
            //Element
            Bottle  : []
        }
    }
    //Element
    componentDidMount(){
        //Element
        this.Load()
        //Element
        this.Featured()
        //Element
        this.Queue()
        //Element
        this.Color()
        //Element
        this.Variety()
        //Element
        this.Colour()
        //Element
        this.Volume()
        //Element
        this.Bottle()
    }
    //Element
    Upgrade = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Title, SubTitle, Summary, Image, Care, Features, Faq, Variety, Bottle, Volume, Date, Author
        //Element
        let PrimaryColor, SecondColor, ThirdColor
        //Element
        let PrimaryImage, SecondImage, ThirdImage
        //Element
        Title        = document.getElementById( "Web_U45Q45" ).value
        //Element
        SubTitle     = document.getElementById( "Web_LK55R0" ).value
        //Element
        Summary      = document.getElementsByClassName( "ck-editor__editable_inline" )[0].innerHTML
        //Element
        Image        = sessionStorage.getItem( "ProductFeaturedImage" )
        //Element
        Care         = document.getElementById( "Web_H912Y9" ).value
        //Element
        Features     = document.getElementById( "Web_R8401X" ).value
        //Element
        Faq          = document.getElementById( "Web_Y050C7" ).value
        //Element
        Variety      = document.getElementById( "Web_GV376I" ).value
        //Element
        Bottle       = document.getElementById( "Web_D642MB" ).value
        //Element
        Volume       = document.getElementById( "Web_T134PW" ).value
        //Element
        Date         = document.getElementById( "Web_P65395" ).value
        //Element
        Author       = document.getElementById( "Web_Y71949" ).value
        //Element
        PrimaryColor = document.getElementById( "Web_GO973H" ).value
        //Element
        SecondColor  = document.getElementById( "Web_EW280E" ).value
        //Element
        ThirdColor   = document.getElementById( "Web_R4399Q" ).value
        //Element
        PrimaryImage = sessionStorage.getItem( "ProductPrimaryImage" )
        //Element
        SecondImage  = sessionStorage.getItem( "ProductSecondImage" )
        //Element
        ThirdImage   = sessionStorage.getItem( "ProductThirdImage" )
        //Element
        Axios.get( "https://api.renga.com.tr/product/update", {
            //Element
            params : {
                //Element
                ID       : this.state.ID,
                //Element
                Title    : Title,
                //Element
                SubTitle : SubTitle,
                //Element
                Summary  : Summary,
                //Element
                Image    : Image,
                //Element
                Care     : Care,
                //Element
                Features : Features,
                //Element
                Faq      : Faq,
                //Element
                Variety  : Variety,
                //Element
                Bottle   : Bottle,
                //Element
                Volume   : Volume,
                //Element
                Date     : Date,
                //Element
                Author   : Author
            }
        })
        //Element
        .catch( Error => {
            //Element
            console.log( Error )
        })
        //Element
        Axios.get( "https://api.renga.com.tr/product/color/update", {
            //Element
            params : {
                //Element
                ID     : this.state.ID,
                //Element
                First  : PrimaryColor,
                //Element
                Second : SecondColor,
                //Element
                Third  : ThirdColor
            }
        })
        .catch( Error => {
            //Element
            console.log( Error )
        })
        //Element
        Axios.get( "https://api.renga.com.tr/product/image/update", {
            //Element
            params : {
                //Element
                ID     : this.state.ID,
                //Element
                First  : PrimaryImage,
                //Element
                Second : SecondImage,
                //Element
                Third  : ThirdImage
            }
        })
        .catch( Error => {
            //Element
            console.log( Error )
        })
        //Element
        window.location.href = "/product-order"
    }
    //Element
    Delete  = (e) => {
        //Element
        e.preventDefault()
        //Element
        Axios.get( "https://api.renga.com.tr/product/delete", {
            //Element
            params : {
                //Element
                ID : this.state.ID
            }
        })
        //Element
        .catch( Error => {
            //Element
            console.log( Error )
        })
        //Element
        Axios.get( "https://api.renga.com.tr/product/color/delete", {
            //Element
            params : {
                //Element
                ID : this.state.ID
            }
        })
        //Element
        .catch( Error => {
            //Element
            console.log( Error )
        })
        //Element
        Axios.get( "https://api.renga.com.tr/product/image/delete", {
            //Element
            params : {
                //Element
                ID : this.state.ID
            }
        })
        //Element
        .catch( Error => {
            //Element
            console.log( Error )
        })
        //Element
        window.location.href = "/product-order"
    }
    //Element
    Colour   = () => {
        //Element
        Axios.get( "https://api.renga.com.tr/product/color/select", {
            //Element
            params : {
                //Element
                ID : this.state.ID
            }
        })
        //Element
        .then( Response => {
            //Element
            Response.data.map( (Data) => {
                //Element
                document.getElementById( "Web_GO973H" ).value = Data.First
                //Element
                document.getElementById( "Web_EW280E" ).value = Data.Second
                //Element
                document.getElementById( "Web_R4399Q" ).value = Data.Third
            })
        })
    }
    //Element
    Variety = () => {
        //Element
        Axios.get( "https://api.renga.com.tr/variety", {} )
        //Element
        .then( Response => {
            //Element
            this.setState({
                //Element
                Variety : Response.data
            })
        })
    }
    //Element
    Color = () => {
        //Element
        Axios.get( "https://api.renga.com.tr/color", {} )
        //Element
        .then( Response => {
            //Element
            this.setState({
                //Element
                Color : Response.data
            })
        })
    }
    //Element
    Volume = () => {
        //Element
        Axios.get( "https://api.renga.com.tr/volume", {} )
        //Element
        .then( Response => {
            //Element
            this.setState({
                //Element
                Volume : Response.data
            })
        })
    }
    //Element
    Bottle = () => {
        //Element
        Axios.get( "https://api.renga.com.tr/bottle", {} )
        //Element
        .then( Response => {
            //Element
            this.setState({
                //Element
                Bottle : Response.data
            })
        })
    }
    //Element
    Load   = () => {
        //Element
        Axios.get( "https://api.renga.com.tr/product/select", {
            //Element
            params : {
                //Element
                ID : this.state.ID
            }
        })
        //Element
        .then( Response => {
            //Element
            Response.data.map( (Data) => {
                //Element
                document.getElementById( "Web_Y71949" ).value                                = Data.Author
                //Element
                document.getElementById( "Web_U45Q45" ).value                                = Data.Title
                //Element
                document.getElementById( "Web_LK55R0" ).value                                = Data.SubTitle
                //Element
                document.getElementsByClassName( "ck-editor__editable_inline" )[0].innerHTML = Data.Summary
                //Element
                document.getElementById( "Web_GV376I" ).value                                = Data.Variety
                //Element
                document.getElementById( "Web_H912Y9" ).value                                = Data.Care
                //Element
                document.getElementById( "Web_R8401X" ).value                                = Data.Features
                //Element
                document.getElementById( "Web_Y050C7" ).value                                = Data.Faq
                //Element
                document.getElementById( "Web_T134PW" ).value                                = Data.Volume
                //Element
                document.getElementById( "Web_D642MB" ).value                                = Data.Bottle
                //Element
                sessionStorage.setItem( "ProductFeaturedImage", Data. Image )
                //Element
                this.setState({
                    //Element
                    Summary : Data.Summary
                })
            })
        })
    }
    //Element
    Featured = () => {
        //Element
        const SVG                = document.getElementsByClassName( "p43687" )[0]
        //Element
        const Image              = document.getElementsByClassName( "klf458" )[0]
        //Element
        let Source               = "https://api.renga.com.tr/images/"
        //Element
        let Photo                = ""
        //Element
        let URL                  = ""
        //Element
        const Preview            = document.getElementById( "ProductPreviewImage" )
        //Element
        Axios.get( "https://api.renga.com.tr/product/select", {
            //Element
            params : {
                //Element
                ID : this.state.ID
            }
        })
        //Element
        .then( Response => {
            //Element
            Response.data.map( (Data) => {
                 //Element
                SVG.classList.add( "d-none" )
                //Element
                Image.classList.remove( "d-none" )
                //Element
                Photo       = Data.Image
                //Element
                URL         = Source + Photo
                //Element
                Preview.src = URL
                //Element
                sessionStorage.getItem( "ProductFeaturedImage", Data.Image )
            })
        })
    }
    //Element
    Queue    = () => {
        //Element
        let Source               = "https://api.renga.com.tr/images/"
        //Element
        let Photo                = ""
        //Element
        let URL                  = ""
        //Element
        const Primary            = document.getElementById( "Web_R494VD" )
        //Element
        const Second             = document.getElementById( "Web_Y123I3" )
        //Element
        const Third              = document.getElementById( "Web_W5F6C8" )
        //Element
        const SVG                = document.getElementsByClassName( "p43687" )[0]
        //Element
        const Image              = document.getElementsByClassName( "klf458" )[0]
        //Element
        Axios.get( "https://api.renga.com.tr/product/image/select", {
            //Element
            params : {
                //Element
                ID : this.state.ID
            }
        })
        //Element
        .then( Response => {
            //Element
            Response.data.map( (Data) => {
                //Element
                if( Data.First != null ){
                    //Element
                    document.getElementById( "Web_V32KA9" ).classList.add( "d-none" )
                    //Element
                    document.getElementById( "Web_V5K852" ).classList.remove( "d-none" )
                    //Element
                    Photo       = Data.First
                    //Element
                    URL         = Source + Photo
                    //Element
                    Primary.src = URL
                    //Element
                    if( sessionStorage.getItem( "ProductPrimaryImage" ) ){
                        //Element
                        sessionStorage.removeItem( "ProductPrimaryImage" )
                        //Element
                        sessionStorage.setItem( "ProductPrimaryImage", Data.First )
                    //Element
                    } else {
                        //Element
                        sessionStorage.setItem( "ProductPrimaryImage", Data.First )
                    }
                }
                //Element
                if( Data.Second != null ){
                    //Element
                    document.getElementById( "Web_QR150K" ).classList.add( "d-none" )
                    //Element
                    document.getElementById( "Web_XG45T3" ).classList.remove( "d-none" )
                    //Element
                    Photo       = Data.Second
                    //Element
                    URL         = Source + Photo
                    //Element
                    Second.src  = Source + Photo
                    //Element
                    if( sessionStorage.getItem( "ProductSecondImage" ) ){
                        //Element
                        sessionStorage.removeItem( "ProductSecondImage" )
                        //Element
                        sessionStorage.setItem( "ProductSecondImage", Data.Second )
                    //Element
                    } else {
                        //Element
                        sessionStorage.setItem( "ProductSecondImage", Data.Second )
                    }
                }
                //Element
                if( Data.Third != null ){
                    //Element
                    document.getElementById( "Web_Q0V66Y" ).classList.add( "d-none" )
                    //Element
                    document.getElementById( "Web_Y1V817" ).classList.remove( "d-none" )
                    //Element
                    Photo       = Data.Third
                    //Element
                    URL         = Source + Photo
                    //Element
                    Third.src  = Source + Photo
                    //Element
                    if( sessionStorage.getItem( "ProductThirdImage" ) ){
                        //Element
                        sessionStorage.removeItem( "ProductThirdImage" )
                        //Element
                        sessionStorage.setItem( "ProductThirdImage", Data.Third )
                    //Element
                    } else {
                        //Element
                        sessionStorage.setItem( "ProductThirdImage", Data.Third )
                    }
                }
            })
        })
    }
    //Element
    render() {
        //Element
        const ID       = this.state.ID
        //Element
        let Now        = new Date()
        //Element
        let Today      = Now.toLocaleDateString( "tr-TR" )
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
                //Element
                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                    //Element
                    "Ürün"
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                    //Element
                    "Renga.com.tr"
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
                //Element
                React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a" },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "ID"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_V180MF", name: "Web_V180MF", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Tarih"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_P65395", name: "Web_P65395", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Today, type: "text", required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Admin"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_Y71949", name: "Web_Y71949", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required", disabled: "disabled" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Ürün Başlığı"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_U45Q45", name: "Web_U45Q45", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Alt Başlığı"
                                ),
                                //Element
                                React.createElement( "input", { id: "Web_LK55R0", name: "Web_LK55R0", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Kategori"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_GV376I", name: "Web_GV376I", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    this.state.Variety.map( Data => {
                                        //Element
                                        return React.createElement( "option", { key: Data.ID, value: Data.ID },
                                            //Element
                                            Data.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Açıklama"
                                ),
                                //Element
                                React.createElement( CKEditor, { editor: ClassicEditor, data: this.state.Summary } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Bakım"
                                ),
                                //Element
                                React.createElement( "textarea", { id: "Web_H912Y9", name: "Web_H912Y9", className: "float-start w-100 p-3 m-0 mt-2 a49170", rows: "5", cols: "20", maxlenght: "500", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Özellikler"
                                ),
                                //Element
                                React.createElement( "textarea", { id: "Web_R8401X", name: "Web_R8401X", className: "float-start w-100 p-3 m-0 mt-2 a49170", rows: "5", cols: "20", maxlenght: "500", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "S.S.S"
                                ),
                                //Element
                                React.createElement( "textarea", { id: "Web_Y050C7", name: "Web_Y050C7", className: "float-start w-100 p-3 m-0 mt-2 a49170", rows: "5", cols: "20", maxlenght: "500", required: "required" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Renk"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_GO973H", name: "Web_GO973H", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    this.state.Color.map( Data => {
                                        //Element
                                        return React.createElement( "option", { key: Data.ID, value: Data.ID },
                                            //Element
                                            Data.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Renk"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_EW280E", name: "Web_EW280E", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    this.state.Color.map( Data => {
                                        //Element
                                        return React.createElement( "option", { key: Data.ID, value: Data.ID },
                                            //Element
                                            Data.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Renk"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_R4399Q", name: "Web_R4399Q", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    this.state.Color.map( Data => {
                                        //Element
                                        return React.createElement( "option", { key: Data.ID, value: Data.ID },
                                            //Element
                                            Data.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Hacim"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_T134PW", name: "Web_T134PW", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    this.state.Volume.map( Data => {
                                        //Element
                                        return React.createElement( "option", { key: Data.ID, value: Data.ID },
                                            //Element
                                            Data.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                                //Element
                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                    //Element
                                    "Matara"
                                ),
                                //Element
                                React.createElement( "select", { id: "Web_D642MB", name: "Web_D642MB", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 position-relative f972xq" },
                                    //Element
                                    this.state.Bottle.map( Data => {
                                        //Element
                                        return React.createElement( "option", { key: Data.ID, value: Data.ID },
                                            //Element
                                            Data.Title
                                        )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 kq045f", htmlFor: "Web_R494VD" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_R494VD", For: "Web_N87TJ3", SVG: "Web_V32KA9", Image: "Web_V5K852" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 kq045f", htmlFor: "Web_Y123I3" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_Y123I3", For: "Web_Y8BN79", SVG: "Web_QR150K", Image: "Web_XG45T3" } )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:4 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                        //Element
                                        React.createElement( "label", { className: "float-start w-100 p-0 m-0 kq045f", htmlFor: "Web_W5F6C8" },
                                            //Element
                                            "Fotoğraf"
                                        ),
                                        //Element
                                        React.createElement( Upload, { ID: "Web_W5F6C8", For: "Web_E2F4K5", SVG: "Web_Q0V66Y", Image: "Web_Y1V817" } )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                            //Element
                                            React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: this.Delete },
                                                //Element
                                                "Sil"
                                            )
                                        )
                                    ),
                                    //Element
                                    React.createElement( Col, { md:6 },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                            //Element
                                            React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: this.Upgrade },
                                                //Element
                                                "Güncelle"
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Edit;