//Install
import React from "react";
//Install
import "swiper/css";
//Install
import "swiper/css/navigation";
//Install
import "swiper/css/pagination";
//Install
import "bootstrap/dist/css/bootstrap.min.css";
//Install
import "./App.css";
//Install
import "./Responsive.css";
//Install
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//Install
import YP4D92 from "./Component/Main";
//Install
import J0O829 from "./Component/Overview/Default";
//Install
import A785A0 from "./Component/Tab/About/Default";
//Install
import U0N72D from "./Component/Tab/Sustainability/Default";
//Install
import C07101 from "./Component/Tab/Resource/Default";
//Install
import V3B010 from "./Component/Tab/Product/Default";
//Install
import E85041 from "./Component/Tab/Product/Order";
//Install
import X693NQ from "./Component/Tab/Product/Update";
//Install
import W872DM from "./Component/Tab/Blog/Default";
//Install
import ZJ575V from "./Component/Tab/Blog/Order";
//Install
import O198C6 from "./Component/Tab/Blog/Update";
//Install
import W9N399 from "./Component/Tab/Document/Default";
//Install
import X60032 from "./Component/Tab/Document/Order";
//Install
import DJR094 from "./Component/Tab/Document/Update";
//Install
import J54TO3 from "./Component/Tab/Prize/Default";
//Install
import BDI653 from "./Component/Tab/Prize/Order";
//Install
import V377ZN from "./Component/Tab/Prize/Update";
//Install
import I240JO from "./Component/Tab/Factory/Default";
//Install
import X810Q3 from "./Component/Tab/Factory/Order";
//Install
import KLW408 from "./Component/Tab/Factory/Update";
//Install
import I2520Z from "./Component/Tab/Fair/Default";
//Install
import HK5520 from "./Component/Tab/Fair/Order";
//Install
import JF5K64 from "./Component/Tab/Fair/Update";
//Install
import Q63Q4Z from "./Component/Tab/Reference/Default";
//Install
import G3P88L from "./Component/Tab/Reference/Order";
//Install
import G0630S from "./Component/Tab/Reference/Update";
//Install
import X99EO2 from "./Component/Tab/Timeline/Default";
//Install
import Q55Z3C from "./Component/Tab/Timeline/Order";
//Install
import W601K3 from "./Component/Tab/Timeline/Update";
//Install
import E72L87 from "./Component/Tab/Application/Default";
//Install
import HK11M3 from "./Component/Tab/Application/Update";
//Install
import TY581I from "./Component/Tab/News/Default";
//Install
import LY071M from "./Component/Tab/News/Update";
//Install
import U322XH from "./Component/Tab/Faq/Default";
//Install
import V97V95 from "./Component/Tab/Faq/Update";
//Install
import D23P84 from "./Component/Tab/Status/Default";
//Install
import J9J56E from "./Component/Tab/Status/Update";
//Install
import V6L239 from "./Component/Tab/Bottle/Default";
//Install
import U62EB0 from "./Component/Tab/Bottle/Update";
//Install
import I36RT9 from "./Component/Tab/Variety/Default";
//Install
import L054BL from "./Component/Tab/Variety/Update";
//Install
import NV23C1 from "./Component/Tab/Color/Default";
//Install
import V18B6V from "./Component/Tab/Color/Update";
//Install
import F359H9 from "./Component/Tab/Volume/Default";
//Install
import A3913J from "./Component/Tab/Volume/Update";
//Install
import I4U24D from "./Component/Tab/Social/Default";
//Install
import DO8B18 from "./Component/Tab/Setting/Default";

//Element
function App() {
  //Element
  return (
    //Element
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <YP4D92 /> }></Route>
          <Route path="/overview" element={ <J0O829 /> }></Route>
          <Route path="/corporation" element={ <A785A0 /> }></Route>
          <Route path="/sustainability" element={ <U0N72D /> }></Route>
          <Route path="/human-resource" element={ <C07101 /> }></Route>
          <Route path="/product" element={ <V3B010 /> }></Route>
          <Route path="/product-order" element={ <E85041 /> }></Route>
          <Route path="/product-edit/:ID" element={ <X693NQ /> }></Route>
          <Route path="/blog" element={ <W872DM /> }></Route>
          <Route path="/blog-order" element={ <ZJ575V /> }></Route>
          <Route path="/blog-edit/:ID" element={ <O198C6 /> }></Route>
          <Route path="/document" element={ <W9N399 /> }></Route>
          <Route path="/document-order" element={ <X60032 /> }></Route>
          <Route path="/document-edit/:ID" element={ <DJR094 /> }></Route>
          <Route path="/prize" element={ <J54TO3 /> }></Route>
          <Route path="/prize-order" element={ <BDI653 /> }></Route>
          <Route path="/prize-edit/:ID" element={ <V377ZN /> }></Route>
          <Route path="/factory" element={ <I240JO /> }></Route>
          <Route path="/factory-order" element={ <X810Q3 /> }></Route>
          <Route path="/factory-edit/:ID" element={ <KLW408 /> }></Route>
          <Route path="/fair" element={ <I2520Z /> }></Route>
          <Route path="/fair-order" element={ <HK5520 /> }></Route>
          <Route path="/fair-edit/:ID" element={ <JF5K64 /> }></Route>
          <Route path="/reference" element={ <Q63Q4Z /> }></Route>
          <Route path="/reference-order" element={ <G3P88L /> }></Route>
          <Route path="/reference-edit/:ID" element={ <G0630S /> }></Route>
          <Route path="/timeline" element={ <X99EO2 /> }></Route>
          <Route path="/timeline-order" element={ <Q55Z3C /> }></Route>
          <Route path="/timeline-update/:ID" element={ <W601K3 /> }></Route>
          <Route path="/application" element={ <E72L87 /> }></Route>
          <Route path="/application-update/:ID" element={ <HK11M3 /> }></Route>
          <Route path="/news" element={ <TY581I /> }></Route>
          <Route path="/news-update/:ID" element={ <LY071M /> }></Route>
          <Route path="/faq" element={ <U322XH /> }></Route>
          <Route path="/faq-update/:ID" element={ <V97V95 /> }></Route>
          <Route path="/status" element={ <D23P84 /> }></Route>
          <Route path="/status-update/:ID" element={ <J9J56E /> }></Route>
          <Route path="/bottle" element={ <V6L239 /> }></Route>
          <Route path="/bottle-update/:ID" element={ <U62EB0 /> }></Route>
          <Route path="/variety" element={ <I36RT9 /> }></Route>
          <Route path="/variety-update/:ID" element={ <L054BL /> }></Route>
          <Route path="/color" element={ <NV23C1 /> }></Route>
          <Route path="/color-update/:ID" element={ <V18B6V /> }></Route>
          <Route path="/volume" element={ <F359H9 /> }></Route>
          <Route path="/volume-update/:ID" element={ <A3913J /> }></Route>
          <Route path="/social" element={ <I4U24D /> }></Route>
          <Route path="/setting" element={ <DO8B18 /> }></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
//Element
export default App;