//Install
import React, { useState, useEffect } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import { ReactComponent as Icon } from "../../../Media/Icon/011.svg";

//Element
function List(){
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Fetch = async() => {
            //Element
            const Public = await Axios.get( "https://api.renga.com.tr/blog", {} )
            //Element
            C68006( Public.data )
        }
        //Element
        Fetch()
    }, [] )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Liste"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Renga.com.tr"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 sh874h" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 n2b78e" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                "Blog ID"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                "Blog Başlığı"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                "Tarih"
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:3 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 d9287e" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 po732b" },
                                //Element
                                ""
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "ol", { className: "float-start w-100 p-0 m-0 p4s8r8" },
                //Element
                G7TI26.map( (Data) => {
                    //Element
                    let URL    = "/blog-edit/"
                    //Element
                    let Target = URL + Data.ID
                    //Element
                    return React.createElement( "li", { key: Data.ID, className: "float-start w-100 p-0 m-0" },
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "label", { className: "float-start w-auto p-0 m-0 text-truncate text-center" },
                                        //Element
                                        Data.ID
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-truncate" },
                                        //Element
                                        Data.Title
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 text-truncate" },
                                        //Element
                                        Data.Date
                                    )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mt-3 jl002z" },
                                    //Element
                                    React.createElement( "a", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 text-truncate", href: Target },
                                        //Element
                                        React.createElement( Icon, {} )
                                    )
                                )
                            )
                        )
                    )
                })
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default List;