//Install
import React, { useState, useEffect } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Header from "../../Header";
//Install
import Footer from "../../Footer";

//Element
function Overview(){
    //Element
    document.title = "Medya - Renga.com.tr"
}
//Element
function Upgrade(e){
    //Element
    e.preventDefault()
    //Element
    let Facebook, Twitter, Instagram, Youtube, Linkedin
    //Element
    Facebook  = document.getElementById( "Web_Q9E75Z" ).value
    //Element
    Twitter   = document.getElementById( "Web_P543KJ" ).value
    //Element
    Instagram = document.getElementById( "Web_MW2845" ).value
    //Element
    Youtube   = document.getElementById( "Web_L6MR97" ).value
    //Element
    Linkedin  = document.getElementById( "Web_HQ5169" ).value
    //Element
    const Fetch = async() => {
        //Element
        const Public = await Axios.get( "https://api.renga.com.tr/social/update", {
            //Element
            params : {
                //Element
                Facebook  : Facebook,
                //Element
                Twitter   : Twitter,
                //Element
                Instagram : Instagram,
                //Element
                Youtube   : Youtube,
                //Element
                Linkedin  : Linkedin
            }
        })
        //Element
        .catch( error => {
            //Element
            console.log( error )
        })
    }
    //Element
    Fetch()
    //Element
    window.location.href = "/social"
}
//Element
function Default(){
    //Element
    Overview()
    //Element
    let ID, Facebook, Twitter, Instagram, Youtube, Linkedin
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Fetch = async() => {
            //Element
            const Public = await Axios.get( "https://api.renga.com.tr/social", {} )
            //Element
            C68006( Public.data )
        }
        //Element
        Fetch()
    }, [] )
    //Element
    G7TI26.map( (Data) => {
        //Element
        ID        = Data.ID
        //Element
        Facebook  = Data.Facebook
        //Element
        Twitter   = Data.Twitter
        //Element
        Instagram = Data.Instagram
        //Element
        Youtube   = Data.Youtube
        //Element
        Linkedin  = Data.Linkedin
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v9339k" },
        //Element
        React.createElement( Header, { ID: "0", Title: "Merhaba, Renga.com.tr", Status: "Medya" } ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ga8x38" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                                    //Element
                                    "Medya"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                                    //Element
                                    "Renga.com.tr"
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
                                //Element
                                React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: Upgrade },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "ID"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_C2U3P7", name: "Web_C2U3P7", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Facebook"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_Q9E75Z", name: "Web_Q9E75Z", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Facebook, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Twitter"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_P543KJ", name: "Web_P543KJ", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Twitter, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Instagram"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_MW2845", name: "Web_MW2845", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Instagram, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Youtube"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_L6MR97", name: "Web_L6MR97", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Youtube, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:4 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                                    //Element
                                                    "Linkedin"
                                                ),
                                                //Element
                                                React.createElement( "input", { id: "Web_HQ5169", name: "Web_HQ5169", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Linkedin, type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                                            //Element
                                            React.createElement( "button", { id: "Web_L9Y32S", name: "Web_L9Y32S", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                                                //Element
                                                "Gönder"
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( Footer, {} )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default;