//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Card extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Target } = this.props
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start position-relative e475k3" },
            //Element
            React.createElement( "h3", { className: "float-start w-auto p-0 m-0 lh-1 rv3198" },
                //Element
                ID
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 hm1203" },
                //Element
                Title
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Card.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Card;