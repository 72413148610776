//Install
import React from "react";

//Element
function SET( Name, Value, Day ) {
    //Element
    let Symbol, Express, On
    //Element
    Symbol          = "express="
    //Element
    On = new Date()
    //Element
    On.setTime( On.getTime() + ( Day * 24 * 60 * 60 * 1000 ) )
    //Element
    Express         = Symbol + On.toUTCString()
    //Element
    document.cookie = Name + "=" + Value + ";" + Express + ";path=/";
    //Element
    return ""
}
//Element
export default SET;