//Install
import React from "react";
//Install
import Axios from "axios";

//Element
function Add(e){
    //Element
    e.preventDefault()
    //Element
    let Title, Date
    //Element
    Title = document.getElementById( "Web_N714AK" ).value
    //Element
    Date  = document.getElementById( "Web_R365S9" ).value
    //Element
    const Fetch = async() => {
        //Element
        const Public = await Axios.get( "https://api.renga.com.tr/news/add", {
            //Element
            params : {
                //Element
                Title : Title,
                //Element
                Date  : Date
            }
        })
        //Element
        .catch( error => {
            //Element
            console.log( error )
        })
    }
    //Element
    Fetch()
    //Element
    window.location.href = "/news"
}
//Element
function New(){
    //Element
    let Now   = new Date()
    //Element
    let Today = Now.toLocaleDateString( "tr-TR" )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Haber"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Renga.com.tr"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: Add },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_N9791J", name: "Web_N9791J", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Adı"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_N714AK", name: "Web_N714AK", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Tarih"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_R365S9", name: "Web_R365S9", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Today, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( "button", { id: "Web_QP779M", name: "Web_QP779M", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                        //Element
                        "Gönder"
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default New;