//Install
import React from "react";
//Install
import Axios from "axios";
//Install
import { Container, Row, Col } from "react-bootstrap";

//Element
function Add(e){
    //Element
    e.preventDefault()
    //Element
    let Title, One, Two, Three, Image
    //Element
    Title = document.getElementById( "Web_G64R9F" ).value
    //Element
    One   = document.getElementById( "Web_P9340F" ).value
    //Element
    Two   = document.getElementById( "Web_BZ457J" ).value
    //Element
    Three = document.getElementById( "Web_P49O4A" ).value
    //Element
    Image = sessionStorage.getItem( "PrizeFeaturedImage" )
    //Element
    const Fetch = async() => {
        //Element
        const Public = await Axios.get( "https://api.renga.com.tr/prize/add", {
            //Element
            params : {
                //Element
                Title : Title,
                //Element
                One   : One,
                //Element
                Two   : Two,
                //Element
                Three : Three,
                //Element
                Image : Image
            }
        })
        //Element
        .catch( error => {
            //Element
            console.log( error )
        })
    }
    //Element
    Fetch()
    //Element
    window.location.href = "/prize-order"
}
//Element
function New(){
    //Element
    let Now   = new Date()
    //Element
    let Today = Now.toLocaleDateString( "tr-TR" )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Ödül"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Renga.com.tr"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET", onSubmit: Add },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_W15250", name: "Web_W15250", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: "0", type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ödül Başlığı"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_G64R9F", name: "Web_G64R9F", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "#1"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_P9340F", name: "Web_P9340F", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "#2"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_BZ457J", name: "Web_BZ457J", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "#3"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_P49O4A", name: "Web_P49O4A", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", type: "text" } )
                        )
                    ),
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                        //Element
                        React.createElement( "button", { id: "Web_Q24564", name: "Web_Q24564", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "submit" },
                            //Element
                            "Gönder"
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default New;