//Install
import React, { Component } from "react";
//Install
import Axios from "axios";
//Install
import { ReactComponent as Icon } from "../../../Media/Icon/012.svg";
//Install
import { ReactComponent as Edit } from "../../../Media/Icon/013.svg";
//Install
import propTypes from "prop-types";

//Element
class Image extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            SelectedFile : null,
            //Element
            FileName     : null
        }
    }
    //Element
    componentDidMount(){
        //Element
        const { Image } = this.props
        //Element
        let Icon        = document.getElementById( Image )
        //Element
        Icon.classList.add( "d-none" )
    }
    //Element
    FileChange = (e) => {
        //Element
        const { ID, For, SVG, Image } = this.props
        //Element
        const Data = new FormData()
        //Element
        let Icon    = document.getElementById( SVG )
        //Element
        let Photo   = document.getElementById( Image )
        //Element
        let Preview = document.getElementById( ID )
        //Element
        Icon.classList.add( "d-none" )
        //Element
        Photo.classList.remove( "d-none" )
        //Element
        if( e.target.files != null ){
            //Element
            this.setState({
                //Element
                SelectedFile : e.target.files[0],
                //Element
                FileName     : e.target.files[0].name
            })
            //Element
            let Object      = URL.createObjectURL( e.target.files[0] )
            //Element
            Preview.src     = Object
            //Element
            Data.append( "image", e.target.files[0] )
            //Element
            if( ID == "Web_R494VD" ){
                //Element
                Axios.post( "https://api.renga.com.tr/upload", Data )
                //Element
                .then( Res => {
                    //Element
                    sessionStorage.setItem( "ProductPrimaryImage", Res.data )
                })
            } else if( ID == "Web_Y123I3" ){
                //Element
                Axios.post( "https://api.renga.com.tr/upload", Data )
                //Element
                .then( Res => {
                    //Element
                    sessionStorage.setItem( "ProductSecondImage", Res.data )
                })
            } else if( ID == "Web_W5F6C8" ){
                //Element
                Axios.post( "https://api.renga.com.tr/upload", Data )
                //Element
                .then( Res => {
                    //Element
                    sessionStorage.setItem( "ProductThirdImage", Res.data )
                })
            }
        }
    }
    //Element
    render() {
        //Element
        const { ID, For, SVG, Image } = this.props
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 ix0q18" },
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 d8z4l3" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 r68ub7" },
                    //Element
                    React.createElement( "div", { id: SVG, className: "float-start w-auto p-0 m-0 s905gi" },
                        //Element
                        React.createElement( Icon, {} )
                    ),
                    //Element
                    React.createElement( "div", { id: Image, className: "float-start w-auto p-0 m-0 klf458" },
                        //Element
                        React.createElement( "img", { id: ID, alt: "Preview Image", title: "Preview Image", src: "", width: "200", height: "auto" } )
                    ),
                    //Element
                    React.createElement( "label", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 position-absolute rounded-circle f5f48j", htmlFor: For },
                        //Element
                        React.createElement( Edit, {} ),
                        //Element
                        React.createElement( "input", { id: For, name: For, className: "d-none", type: "file", accept: ".png, .jpg, .jpeg", onChange: this.FileChange } )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Image.propTypes = {
    ID    : propTypes.string.isRequired,
    For   : propTypes.string.isRequired,
    SVG   : propTypes.string.isRequired,
    Image : propTypes.string.isRequired
}
//Element
export default Image;