//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function Upgrade(e){ 
    //Element
    e.preventDefault()
    //Element
    let ID, Title, Date
    //Element
    ID    = document.getElementById( "Web_WJ611K" ).value
    //Element
    Title = document.getElementById( "Web_F2K32H" ).value
    //Element
    Date  = document.getElementById( "Web_QX7787" ).value
    //Element
    const Fetch = async() => {
        //Element
        const Public = await Axios.get( "https://api.renga.com.tr/volume/update", {
            //Element
            params : {
                //Element
                ID    : ID,
                //Element
                Title : Title,
                //Element
                Date  : Date
            }
        })
        //Element
        .catch( error => {
            //Element
            console.log( error )
        })
    }
    //Element
    Fetch()
    //Element
    window.location.href = "/volume"
}
//Element
function Delete(e){ 
    //Element
    e.preventDefault()
    //Element
    let ID
    //Element
    ID = document.getElementById( "Web_WJ611K" ).value
    //Element
    const Fetch = async() => {
        //Element
        const Public = await Axios.get( "https://api.renga.com.tr/volume/delete", {
            //Element
            params : {
                //Element
                ID : ID
            }
        })
        //Element
        .catch( error => {
            //Element
            console.log( error )
        })
    }
    //Element
    Fetch()
    //Element
    window.location.href = "/volume"
}
//Element
function Edit(){
    //Element
    let Now   = new Date()
    //Element
    let Today = Now.toLocaleDateString( "tr-TR" )
    //Element
    const { ID } = useParams()
    //Element
    let Title
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Fetch = async() => {
            //Element
            const Public = await Axios.get( "https://api.renga.com.tr/volume/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            } )
            //Element
            C68006( Public.data )
        }
        //Element
        Fetch()
    }, [] )
    //Element
    G7TI26.map( (Data) => {
        //Element
        Title = Data.Title
    })
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Bilgi Güncelleme"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Renga.com.tr"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Hacim ID"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_WJ611K", name: "Web_WJ611K", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Hacim Adı"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_F2K32H", name: "Web_F2K32H", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Title, type: "text", required: "required" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                    //Element
                    React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                        //Element
                        "Tarih"
                    ),
                    //Element
                    React.createElement( "input", { id: "Web_QX7787", name: "Web_QX7787", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Today, type: "text", required: "required", disabled: "disabled" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: Delete },
                                    //Element
                                    "Sil"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                //Element
                                React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: Upgrade },
                                    //Element
                                    "Güncelle"
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;