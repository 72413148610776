//Install
import React, { useState, useEffect } from "react";
//Install
import { useParams } from "react-router-dom";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";

//Element
function Upgrade(e){ 
    //Element
    e.preventDefault()
    //Element
    let ID, Name, Surname, Date, Email, Telephone, Address, City, Day, Month, Year, Gender, Scholl, Section, Graduation, Position, CV, Letter
    //Element
    ID         = document.getElementById( "Web_WJ611K" ).value
    //Element
    Name       = document.getElementById( "Web_S1T1X4" ).value
    //Element
    Surname    = document.getElementById( "Web_CV18D1" ).value
    //Element
    Date       = document.getElementById( "Web_D8G820" ).value
    //Element
    Email      = document.getElementById( "Web_L921LX" ).value
    //Element
    Telephone  = document.getElementById( "Web_A9ZV20" ).value
    //Element
    Address    = document.getElementById( "Web_E8H67X" ).value
    //Element
    City       = document.getElementById( "Web_D1751Z" ).value
    //Element
    Day        = document.getElementById( "Web_L7DD70" ).value
    //Element
    Month      = document.getElementById( "Web_K61375" ).value
    //Element
    Year       = document.getElementById( "Web_D2N2X3" ).value
    //Element
    Gender     = document.getElementById( "Web_G946P0" ).value
    //Element
    Scholl     = document.getElementById( "Web_QM56A5" ).value
    //Element
    Section    = document.getElementById( "Web_U64Q7N" ).value
    //Element
    Graduation = document.getElementById( "Web_T577P1" ).value
    //Element
    Position   = document.getElementById( "Web_I16764" ).value
    //Element
    CV         = sessionStorage.getItem( "CV" )
    //Element
    Letter     = document.getElementById( "Web_R2U0Q8" ).value
    //Element
    const Fetch = async() => {
        //Element
        const Public = await Axios.get( "https://api.renga.com.tr/application/update", {
            //Element
            params : {
                //Element
                ID         : ID,
                //Element
                Name       : Name,
                //Element
                Surname    : Surname,
                //Element
                Date       : Date,
                //Element
                Email      : Email,
                //Element
                Telephone  : Telephone,
                //Element
                Address    : Address,
                //Element
                City       : City,
                //Element
                Day        : Day,
                //Element
                Month      : Month,
                //Element
                Gender     : Gender,
                //Element
                Year       : Year,
                //Element
                Scholl     : Scholl,
                //Element
                Section    : Section,
                //Element
                Graduation : Graduation,
                //Element
                Position   : Position,
                //Element
                CV         : CV,
                //Element
                Letter     : Letter
            }
        })
        //Element
        .catch( error => {
            //Element
            console.log( error )
        })
    }
    //Element
    Fetch()
    //Element
    window.location.href = "/application"
}
//Element
function Delete(e){ 
    //Element
    e.preventDefault()
    //Element
    let ID
    //Element
    ID = document.getElementById( "Web_WJ611K" ).value
    //Element
    const Fetch = async() => {
        //Element
        const Public = await Axios.get( "https://api.renga.com.tr/application/delete", {
            //Element
            params : {
                //Element
                ID : ID
            }
        })
        //Element
        .catch( error => {
            //Element
            console.log( error )
        })
    }
    //Element
    Fetch()
    //Element
    window.location.href = "/application"
}
//Element
function Edit(){
    //Element
    const { ID } = useParams()
    //Element
    let Name, Surname, Date, Email, Telephone, Address, City, Day, Month, Year, Gender, Scholl, Section, Graduation, Position, CV, Letter
    //Element
    const [ G7TI26, C68006 ] = React.useState([])
    //Element
    useEffect( () => {
        //Element
        const Fetch = async() => {
            //Element
            const Public = await Axios.get( "https://api.renga.com.tr/application/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            } )
            //Element
            C68006( Public.data )
        }
        //Element
        Fetch()
    }, [] )
    //Element
    G7TI26.map( (Data) => {
        //Element
        Name         = Data.Name
        //Element
        Surname      = Data.Surname
        //Element
        Date         = Data.Date
        //Element
        Email        = Data.Email
        //Element
        Telephone    = Data.Telephone
        //Element
        Address      = Data.Address
        //Element
        City         = Data.City
        //Element
        Day          = Data.Day
        //Element
        Month        = Data.Month
        //Element
        Gender       = Data.Gender
        //Element
        Year         = Data.Year
        //Element
        Scholl       = Data.Scholl
        //Element
        Section      = Data.Section
        //Element
        Graduation   = Data.Graduation
        //Element
        Position     = Data.Position
        //Element
        CV           = Data.CV
        //Element
        Letter       = Data.Letter
    })
    //Element
    let Source     = "https://api.renga.com.tr/images/"
    //Element
    let Download   = Source + CV
    //Element
    sessionStorage.setItem( "CV", CV )
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 position-relative d93oi9" },
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 pb-3 m-0 c971b1" },
            //Element
            React.createElement( "h1", { className: "float-start w-100 p-0 m-0 uh0823" },
                //Element
                "Bilgi Güncelleme"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 o6a700" },
                //Element
                "Renga.com.tr"
            )
        ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ix0q18" },
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 mt-3 v2g96a", method: "GET" },
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "ID"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_WJ611K", name: "Web_WJ611K", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: ID, type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Tarih"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_D8G820", name: "Web_D8G820", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Date, type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Şehir"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_D1751Z", name: "Web_D1751Z", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: City, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ad"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_S1T1X4", name: "Web_S1T1X4", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Name, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Soyad"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_CV18D1", name: "Web_CV18D1", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Surname, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Email"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_L921LX", name: "Web_L921LX", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Email, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Telefon"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_A9ZV20", name: "Web_A9ZV20", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Telephone, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:8 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Adres"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_E8H67X", name: "Web_E8H67X", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Address, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Gün"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_L7DD70", name: "Web_L7DD70", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Day, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ay"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_K61375", name: "Web_K61375", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Month, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Yıl"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_D2N2X3", name: "Web_D2N2X3", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Year, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Cinsiyet"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_G946P0", name: "Web_G946P0", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Gender, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:8 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Başvurulan Pozisyon"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_I16764", name: "Web_I16764", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Position, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Okul"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_QM56A5", name: "Web_QM56A5", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Scholl, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Bölüm"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_U64Q7N", name: "Web_U64Q7N", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Section, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:4 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Yıl"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_T577P1", name: "Web_T577P1", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Graduation, type: "text", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "CV"
                            ),
                            //Element
                            React.createElement( "input", { id: "Web_H976TK", name: "Web_H976TK", className: "float-start w-100 p-0 ps-3 pe-3 m-0 mt-2 x174vk", defaultValue: Download, type: "text", required: "required", disabled: "disabled" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 c79t31" },
                            //Element
                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 y944m5" },
                                //Element
                                "Ön Yazı"
                            ),
                            //Element
                            React.createElement( "textarea", { id: "Web_R2U0Q8", name: "Web_R2U0Q8", className: "float-start w-100 p-3 m-0 mt-2 k1s43b", value: Letter, rows: "5", cols: "20", required: "required" } )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 c79t31" },
                            //Element
                            React.createElement( Row, {},
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                        //Element
                                        React.createElement( "button", { id: "Web_IF6E80", name: "Web_IF6E80", className: "float-start w-100 p-0 m-0 text-center y5m320", type: "button", onClick: Delete },
                                            //Element
                                            "Sil"
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( Col, { md:6 },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 t7095e" },
                                        //Element
                                        React.createElement( "button", { id: "Web_N7G0G4", name: "Web_N7G0G4", className: "float-start w-100 p-0 m-0 text-center z4256t", type: "button", onClick: Upgrade },
                                            //Element
                                            "Güncelle"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Edit;