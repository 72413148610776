//Install
import React from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Header from "../../Header";
//Install
import Footer from "../../Footer";
//Install
import List from "./List";
//Install
import New from "./New";

//Element
function Overview(){
    //Element
    document.title = "Kariyer - Renga.com.tr"
}
//Element
function Default(){
    //Element
    Overview()
    //Element
    const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 v9339k" },
        //Element
        React.createElement( Header, { ID: "0", Title: "Merhaba, Renga.com.tr", Status: "Kariyer" } ),
        //Element
        React.createElement( "div", { className: "float-start w-100 p-0 m-0 ga8x38" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( List, {} )
                    ),
                    //Element
                    React.createElement( Col, { md:6 },
                        //Element
                        React.createElement( New, {} )
                    )
                )
            )
        ),
        //Element
        React.createElement( Footer, {} )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default;